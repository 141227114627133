.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack, .applicant-applications__my-application .application-card .application-card__card-front .scholarship-status-message, .applicant-applications__my-application .application-card .application-card__card-front .scholarship-award-amount-message {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite, .applicant-applications__my-application .application-card .application-card__card-front .scholarship-name, .applicant-applications__my-application .application-card .application-card__card-front .scholarship-recommendation {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.application-filter-section-title {
  margin-bottom: 30px;
  text-align: center; }

.applications-heading-and-search-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 768.98px) {
    .applications-heading-and-search-bar {
      width: 50%; } }
  @media (max-width: 1023.98px) {
    .applications-heading-and-search-bar {
      width: 50%; } }
  @media (max-width: 479.98px) {
    .applications-heading-and-search-bar {
      width: 100%; } }
  .applications-heading-and-search-bar input {
    width: 325px;
    height: 46px;
    border-radius: 4px;
    padding-left: 18px;
    border: 1px solid #1e5558; }
    @media (max-width: 768.98px) {
      .applications-heading-and-search-bar input {
        width: 100%; } }
    @media (max-width: 1023.98px) {
      .applications-heading-and-search-bar input {
        width: 100%; } }
  .applications-heading-and-search-bar .applications-heading {
    font-family: 'Inter-Medium';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a282c; }
    @media (max-width: 479.98px) {
      .applications-heading-and-search-bar .applications-heading {
        margin-bottom: 10px; } }

.applicant-applications__my-application {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px; }
  @media (max-width: 1023.98px) {
    .applicant-applications__my-application {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 768.98px) {
    .applicant-applications__my-application {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 479.98px) {
    .applicant-applications__my-application {
      grid-template-columns: 1fr !important; } }
  .applicant-applications__my-application .application-card {
    position: relative;
    padding: 0px 0px 0px 0px;
    border-radius: 10px; }
    .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__content .list-items li {
      padding-bottom: 10px;
      margin-bottom: 35px;
      color: #1a282c; }
      .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__content .list-items li:first-child {
        margin-top: 115px; }
    .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right; }
      .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__footer .brand-mark {
        width: 32px;
        height: 32px; }
      .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__footer .powered-by {
        margin-top: 220px; }
        .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__footer .powered-by .BodyDefault {
          color: #1a282c; }
      .applicant-applications__my-application .application-card .application-card__card-back .application-card__card-back__footer .scholarship-recommendation .scholarship-recommendation-Left-part .BodyDefault {
        font-size: 14px; }
    .applicant-applications__my-application .application-card .application-card__card-front {
      background: #1E5558;
      padding: 24px;
      position: absolute;
      min-height: 410px;
      max-height: 410px;
      right: 0;
      width: 75%; }
      .applicant-applications__my-application .application-card .application-card__card-front .application-card__card-front__logo {
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
        object-fit: contain; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-logo {
        width: 100px;
        height: 100px;
        margin-bottom: 15px; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-name {
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        min-height: 70px; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-recommendation {
        display: flex;
        margin-bottom: 30px;
        min-height: 35px; }
        .applicant-applications__my-application .application-card .application-card__card-front .scholarship-recommendation svg {
          width: 12px;
          height: 12px;
          margin-right: 0px; }
        .applicant-applications__my-application .application-card .application-card__card-front .scholarship-recommendation .scholarship-recommendation-right-part .boxWithIcon {
          background: #fff;
          padding: 3px 7px;
          border-radius: 4px;
          font-size: 20px;
          cursor: pointer; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-status-message {
        color: #ffffff;
        margin-bottom: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 90px; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-award-amount-message {
        color: #ffffff;
        font-size: 20px; }
      .applicant-applications__my-application .application-card .application-card__card-front .scholarship-user-message {
        border-top: 1px solid #ffffff;
        display: flex;
        justify-content: space-between;
        cursor: pointer; }
        .applicant-applications__my-application .application-card .application-card__card-front .scholarship-user-message .scholarship-user-message__left-side-content {
          font-family: 'Inter-Medium';
          font-size: 16px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #ffffff;
          padding-top: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .applicant-applications__my-application .application-card .application-card__card-front .scholarship-user-message .scholarship-user-message__right-side-content {
          padding-top: 18px;
          display: flex;
          align-items: center; }
          .applicant-applications__my-application .application-card .application-card__card-front .scholarship-user-message .scholarship-user-message__right-side-content .arrow-right {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 7px solid #ffffff;
            cursor: pointer; }
    .applicant-applications__my-application .application-card .application-card__pin-container {
      position: absolute;
      right: 10px;
      top: -19px;
      z-index: 2; }
      .applicant-applications__my-application .application-card .application-card__pin-container .paper-clip {
        height: 92px; }
    .applicant-applications__my-application .application-card .application-card__recommendation-popup {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #fff;
      z-index: 2;
      overflow-y: auto;
      display: none; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup.recommendation-popup__show {
        display: block; }
        .applicant-applications__my-application .application-card .application-card__recommendation-popup.recommendation-popup__show .application-card-recommendation-popup-main-cointainer {
          padding-top: 10px; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup.recommendation-popup__hide {
        display: none; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .application-review-section__section-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        min-height: 25px;
        line-height: 1.5;
        color: #1a282c;
        font-family: 'Inter-Medium';
        font-size: 18px;
        font-weight: 400;
        background-color: #f6f6f6;
        border-radius: 5px;
        padding: 10px;
        letter-spacing: normal; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .application-card__recommendation-popup-closed-btn {
        display: flex;
        justify-content: center;
        float: right;
        cursor: pointer; }
        .applicant-applications__my-application .application-card .application-card__recommendation-popup .application-card__recommendation-popup-closed-btn.top-btn svg {
          margin-right: 10px; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .recommendation-list__complete-item-icon {
        margin-right: 10px; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .recommendation-list__complete .H2DesktopGreen {
        font-size: 20px;
        margin-bottom: 10px;
        text-transform: uppercase;
        padding-left: 5px; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .recommendation-list__complete .recommendation-list__complete-item {
        border-bottom: 1px dashed #e6e9d7;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        align-items: center; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .recommendation-list__un-complete .H2DesktopGreen {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #FF542D;
        text-transform: uppercase;
        margin-left: 5px; }
      .applicant-applications__my-application .application-card .application-card__recommendation-popup .recommendation-list__un-complete .recommendation-list__complete-item {
        border-bottom: 1px dashed #e6e9d7;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        align-items: center; }

.view-all-view-less-container {
  display: flex;
  justify-content: center; }
  .view-all-view-less-container .view-all-view-less {
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    transition: transform 0.25s;
    color: #ffffff;
    background-color: #1C21DF;
    border: 1px solid #1C21DF;
    letter-spacing: normal; }

.user-account-page .user-account-page-user-details-section-main {
  padding: 100px 70px 13px 70px; }
  @media (max-width: 479.98px) {
    .user-account-page .user-account-page-user-details-section-main {
      padding: 30px 20px 20px 20px; } }
  @media (max-width: 768.98px) {
    .user-account-page .user-account-page-user-details-section-main {
      padding: 20px 30px 13px 30px; } }
  .user-account-page .user-account-page-user-details-section-main .tab-content {
    padding: 20px 0px 20px 0px; }
  .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 479.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container {
        display: block;
        align-items: flex-start;
        justify-content: flex-start; } }
    @media (max-width: 768.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container {
        display: block;
        align-items: flex-start;
        justify-content: flex-start; } }
    @media (max-width: 1023.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container {
        display: block; } }
    @media (max-width: 1023.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-top: 10px; } }
    @media (max-width: 768.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-top: 10px; } }
    @media (max-width: 479.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container {
        display: block; } }
    @media (max-width: 768.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container {
        width: 50%;
        padding-top: 10px; } }
    @media (max-width: 1023.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container {
        width: 50%;
        padding-top: 10px; } }
    @media (max-width: 479.98px) {
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container {
        width: 100%; } }
    .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container .program-sponsors.applicant-accounts-dropdown {
      margin-left: 0px !important;
      margin-right: 0px !important; }
      .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container .program-sponsors.applicant-accounts-dropdown .field {
        margin-bottom: 10px; }
        .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container .program-sponsors.applicant-accounts-dropdown .field .CTAGrey .H6DesktopBlack {
          margin-top: 0px; }
          .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container .program-sponsors.applicant-accounts-dropdown .field .CTAGrey .H6DesktopBlack.dropdown-with-border > div {
            margin-top: 0px; }
          .user-account-page .user-account-page-user-details-section-main .filter-and-search-application-container .program-sponsors-and-search-application-container .program-sponsors-container .program-sponsors.applicant-accounts-dropdown .field .CTAGrey .H6DesktopBlack div[class$="-Control"] {
            border: 1px solid #1E5558;
            border-radius: 4px;
            min-height: 54px;
            padding: 0 10px;
            margin-top: 0px;
            margin-bottom: 0px; }

.user-account-page .user-application-page-user-details-section {
  margin-bottom: 32px !important;
  padding: 10px 20px 0px 20px; }
  @media (max-width: 479.98px) {
    .user-account-page .user-application-page-user-details-section {
      margin-bottom: 22px !important;
      padding: 10px 10px 0px 10px; } }
  .user-account-page .user-application-page-user-details-section .username-and-year .page-heading {
    margin-bottom: 15px; }

.user-account-page .event-head {
  margin-bottom: 20px !important; }

.user-account-page .application-card-box .application-filter-section-title {
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 2px dashed #D9D9C9;
  padding-bottom: 20px; }
  .user-account-page .application-card-box .application-filter-section-title svg {
    margin-right: 10px; }

/* Style for the unordered list */
.applications-filter-tabs {
  list-style: none;
  margin: 0;
  padding: 0; }

/* Style for the list items */
.applications-filter-tab {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid black;
  margin-right: 10px;
  cursor: pointer; }

/* Style for the selected list item */
.applications-filter-tab.active {
  background-color: #cceeff;
  color: #fff; }

.tabs {
  display: flex;
  padding-left: 20px; }
  @media (max-width: 479.98px) {
    .tabs {
      padding-left: 0px;
      font-size: 13px !important;
      margin-bottom: 15px; }
      .tabs .BodyDefaultRegularBlack {
        display: none; } }
  .tabs .BodyDefaultRegularBlack {
    margin-top: 8px;
    margin-right: 20px; }

.tab {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; }
  @media (max-width: 479.98px) {
    .tab {
      padding: 10px 5px 10px 5px;
      margin-right: 5px; } }

.tab.active {
  background-color: #cceeff; }

.tab-content {
  padding: 20px; }
  @media (max-width: 479.98px) {
    .tab-content {
      padding: 0px; } }
