.application-card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 29px 20px 18px; }
  @media (max-width: 768.98px) {
    .application-card {
      max-width: unset;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); } }
  .application-card:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
  .application-card__wrapper-link {
    display: inherit; }
  .application-card__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px; }
  .application-card__logo {
    max-height: 48px; }
  .application-card__award {
    font-family: "Inter-Medium";
    font-size: 23px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-right: 10px; }
  .application-card__title {
    margin-bottom: 16px; }
  .application-card__excerpt {
    margin-bottom: 58px; }
  .application-card__tag {
    padding: 5px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px; }
  .application-card__tags {
    margin-bottom: 24px; }
  .application-card__date {
    display: flex;
    align-items: center;
    margin-top: auto;
    border-top: solid 1px #f0f2f2;
    padding-top: 13px; }
  .application-card__date-label {
    font-family: "Inter-Medium";
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #434445;
    margin-left: 8px; }
    @media (max-width: 1023.98px) {
      .application-card__date-label {
        line-height: 1.2; } }

.scholarship-recommendation {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .scholarship-recommendation .scholarship-recommendation-Left-part .scholarship-recommendation-icon__container {
    font-size: 14px;
    line-height: 12px;
    font-weight: 300; }
  .scholarship-recommendation .scholarship-recommendation-Left-part .BodyDefault {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px; }
  .scholarship-recommendation .scholarship-recommendation-Left-part .scholarship-recommendation__progress-bar .progress-complete-cell-type .progress-complete-cell-type__text {
    display: none; }
  .scholarship-recommendation .scholarship-recommendation-right-part .arrow-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #ffffff;
    cursor: pointer; }
  .scholarship-recommendation .scholarship-recommendation__icon {
    margin-right: 10px; }
  .scholarship-recommendation .recommendation-poper {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #1E5558;
    cursor: pointer;
    margin-left: 10px; }
  .scholarship-recommendation .progress-complete-cell-type__bar {
    background-color: rgba(246, 246, 246, 0.5); }
    .scholarship-recommendation .progress-complete-cell-type__bar.active {
      background-color: #ffffff; }

.modal.applied-application__List .applied-applications .application-card-main-container {
  min-height: 390px;
  max-height: 390px; }
  .modal.applied-application__List .applied-applications .application-card-main-container__new-container {
    min-height: 230px;
    max-height: 230px; }
  .modal.applied-application__List .applied-applications .application-card-main-container__scholarship-name {
    margin-bottom: 10px;
    font-size: 18px; }
  .modal.applied-application__List .applied-applications .application-card-main-container__scholarship-deadline-date {
    margin-bottom: 10px; }
  .modal.applied-application__List .applied-applications .application-card-main-container__view-award-program-and-complete-application {
    justify-content: unset; }
    .modal.applied-application__List .applied-applications .application-card-main-container__view-award-program-and-complete-application__view-award-program {
      display: none !important; }
    .modal.applied-application__List .applied-applications .application-card-main-container__view-award-program-and-complete-application__view-award-program-arrow {
      display: none; }
    .modal.applied-application__List .applied-applications .application-card-main-container__view-award-program-and-complete-application__complete-application {
      padding-right: 10px;
      font-size: 14px;
      text-transform: capitalize; }

.application-card-main-container {
  border: 1px solid #979797;
  border-top: 10px solid #1C21DF;
  border-radius: 5px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  min-height: 400px;
  max-height: 400px;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #1a282c;
  background-color: #ffffff !important; }
  @media (max-width: 768.98px) {
    .application-card-main-container {
      max-width: unset;
      max-height: 400px;
      min-height: 400px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); } }
  .application-card-main-container__days-left {
    min-height: 16px;
    text-align: end;
    margin-bottom: 5px; }
  .application-card-main-container__logo-and-days-left {
    display: flex;
    justify-content: center;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 10px; }
    .application-card-main-container__logo-and-days-left__logo img {
      max-height: 100px;
      max-width: 100%;
      min-height: 100px;
      object-position: top;
      object-fit: contain; }
  .application-card-main-container__tbf-award-amount {
    margin-bottom: 10px; }
  .application-card-main-container__new-container {
    max-height: 235px;
    min-height: 235px; }
    @media (max-width: 768.98px) {
      .application-card-main-container__new-container {
        max-height: 240px;
        min-height: 240px; } }
  .application-card-main-container__scholarship-name {
    font-size: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #1a282c;
    line-height: normal; }
    @media (max-width: 768.98px) {
      .application-card-main-container__scholarship-name {
        font-size: 16px; } }
  .application-card-main-container__nominee-name {
    margin-bottom: 10px; }
  .application-card-main-container__scholarship-deadline-date {
    margin-bottom: 20px;
    font-size: 15px; }
    @media (max-width: 768.98px) {
      .application-card-main-container__scholarship-deadline-date {
        font-size: 14px; } }
  .application-card-main-container__program-status {
    font-size: 15px; }
    @media (max-width: 768.98px) {
      .application-card-main-container__program-status {
        font-size: 13px; } }
    .application-card-main-container__program-status__title {
      margin-bottom: 8px;
      font-size: 15px; }
      @media (max-width: 768.98px) {
        .application-card-main-container__program-status__title {
          font-size: 13px; } }
    .application-card-main-container__program-status__badge {
      margin-bottom: 20px;
      font-size: 13px;
      color: #592840;
      background-color: #eceaea;
      padding: 5px 10px 5px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      margin-right: 5px; }
  .application-card-main-container__program-status-tbf-award-amount {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 15px; }
  .application-card-main-container__tbf-award-amount-container {
    margin-top: 22px; }
    .application-card-main-container__tbf-award-amount-container__tbf-award-title {
      margin-bottom: 8px;
      font-size: 15px; }
      @media (max-width: 768.98px) {
        .application-card-main-container__tbf-award-amount-container__tbf-award-title {
          font-size: 13px; } }
    .application-card-main-container__tbf-award-amount-container__tbf-award-amount-show {
      font-size: 13px;
      color: #592840;
      background-color: #eceaea;
      padding: 5px 10px 5px 10px;
      max-width: 200px; }
      .application-card-main-container__tbf-award-amount-container__tbf-award-amount-show .tbf-award-amount-notes {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
      @media (max-width: 768.98px) {
        .application-card-main-container__tbf-award-amount-container__tbf-award-amount-show {
          font-size: 13px; } }
  .application-card-main-container__application-status-and-contributor-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 10px; }
    @media (max-width: 768.98px) {
      .application-card-main-container__application-status-and-contributor-status {
        font-size: 13px; } }
    .application-card-main-container__application-status-and-contributor-status__title {
      margin-bottom: 8px; }
    .application-card-main-container__application-status-and-contributor-status__application-status {
      margin-right: 5px; }
    .application-card-main-container__application-status-and-contributor-status__app-status {
      color: #592840;
      background-color: #eceaea;
      padding: 5px 10px 5px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 13px; }
    .application-card-main-container__application-status-and-contributor-status__badge {
      color: #592840;
      background-color: #eceaea;
      padding: 5px 10px 5px 10px;
      font-size: 13px; }
  .application-card-main-container__view-award-program-and-complete-application {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #1C21DF;
    min-height: 30px;
    font-size: 13px; }
    @media (max-width: 768.98px) {
      .application-card-main-container__view-award-program-and-complete-application {
        min-height: 30px;
        margin-top: 10px;
        font-size: 12px !important; } }
    .application-card-main-container__view-award-program-and-complete-application__view-award-program {
      cursor: pointer;
      color: #1C21DF; }
    .application-card-main-container__view-award-program-and-complete-application__complete-application {
      cursor: pointer;
      color: #1C21DF; }
    .application-card-main-container__view-award-program-and-complete-application .account-switcher__arrow {
      cursor: pointer;
      margin: 0 5px;
      min-width: 14px; }
      .application-card-main-container__view-award-program-and-complete-application .account-switcher__arrow path {
        fill: #1C21DF !important; }
